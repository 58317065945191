import { FC } from "react";
import { Table } from "../../Table";
import { useTranslation } from "react-i18next";

type DataDicTableProps = {
  dataDict: any[];
};

export const DataDicTable: FC<DataDicTableProps> = ({ dataDict }) => {
  const { t } = useTranslation();
  const columns = [
    { key: "attribute", label: t("visualization_page.attributes") },
    { key: "type", label: t("visualization_page.data_type") },
    { key: "desc", label: t("visualization_page.description") },
  ];

  return <Table columns={columns} dataSource={dataDict} />;
};
