import Highcharts from "highcharts";
import exportingModule from "highcharts/modules/exporting";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import { VisualizationPage } from "./pages/Visualization";
import { Header } from "./components/layout/Header";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Navbar } from "./components/layout/Navbar";
import { APIProvider } from "@vis.gl/react-google-maps";

exportingModule(Highcharts);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          a: "A",
          header: {
            login: "Login",
            register: "Register",
          },
          navbar: {
            home: "Home",
            dataset: "Dataset",
            group: "Group",
            organization: "Organization",
            about: "About",
            data_request: "Data Request",
            announcement: "Announcement",
          },
          breadcrumb: {
            visualization: "Visualization",
          },
          visualization_page: {
            visualization: "Visualization",
            table: "Table",
            graph: "Graph",
            map: "Map",
            attributes: "Attributes",
            search: "Search...",
            x_axis: "X Axis",
            y_axis: "Y Axis",
            reset: "Reset",
            count: "Count",
            sum: "Sum",
            min: "Min",
            max: "Max",
            avg: "Avg",
            data_dictionary: "Data Dictionary",
            data_type: "Data Type",
            description: "Description",
            column: "Column",
            bar: "Bar",
            line: "Line",
            area: "Area",
            pie: "Pie",
          },
        },
      },
      th: {
        translation: {
          a: "ก",
          header: {
            login: "เข้าสู่ระบบ",
            register: "ลงทะเบียน",
          },
          navbar: {
            home: "หน้าหลัก",
            dataset: "ชุดข้อมูล",
            group: "หมวดหมู่",
            organization: "หน่วยงาน",
            about: "เกี่ยวกับ",
            data_request: "ร้องขอชุดข้อมูล",
            announcement: "ประกาศ",
          },
          breadcrumb: {
            visualization: "Visualization",
          },
          visualization_page: {
            visualization: "Visualization",
            table: "ตาราง",
            graph: "กราฟ",
            map: "แผนที่",
            attributes: "คุณสมบัติ",
            search: "ค้นหา...",
            x_axis: "แกน X",
            y_axis: "แกน Y",
            reset: "รีเซ็ต",
            count: "นับจำนวน",
            sum: "รวม",
            min: "น้อยสุด",
            max: "มากสุด",
            avg: "เฉลี่ย",
            data_dictionary: "พจนานุกรมข้อมูล",
            data_type: "ประเภทข้อมูล",
            description: "คำอธิบาย",
            column: "คอลัมน์",
            bar: "แท่ง",
            line: "เส้น",
            area: "พื้นที่",
            pie: "วงกลม",
          },
        },
      },
    },
    lng: "th", // if you're using a language detector, do not define the lng option
    fallbackLng: "th",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  const { i18n } = useTranslation();
  const [cookie] = useCookies(["user_lang"]);

  useEffect(() => {
    i18n.changeLanguage(cookie.user_lang || "th");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <APIProvider apiKey={"AIzaSyAGu6Bn4T7vR_M2UkAxZtJIKR4b8UkiqOA"}>
      <Header />
      <Navbar />

      <div className="m-auto w-full bg-[#eee]">
        <VisualizationPage />
      </div>
    </APIProvider>
  );
}

export default App;
