import { CollisionBehavior, InfoWindow, Map } from "@vis.gl/react-google-maps";
import React, { FC, useCallback, useMemo, useState } from "react";
import { GGMarker } from "./components/GGMarker";

type TabGoogleMapProps = {
  dataSource: any[];
};

export const TabGoogleMap: FC<TabGoogleMapProps> = ({ dataSource }) => {
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedMarker, setSelectedMarker] =
    useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  const getLatitude = (data: any) => {
    return Number(data?.latitude || data?.Latitude || data?.lat || data?.Lat);
  };

  const getLongitude = (data: any) => {
    return Number(data?.longitude || data?.Longitude || data?.lon || data?.Lon);
  };

  const defaultMarkerCenter = [12.9236, 100.8824];

  const { markerCenter, markerList } = useMemo(() => {
    const markerList = dataSource.map((data) => {
      const latitude = getLatitude(data);
      const longitude = getLongitude(data);

      return {
        ...data,
        ...(latitude && longitude && { marker: [latitude, longitude] }),
      };
    });

    const firstItem = dataSource[0];
    const markerCenter = [
      getLatitude(firstItem) || defaultMarkerCenter[0],
      getLongitude(firstItem) || defaultMarkerCenter[1],
    ];

    return { markerCenter, markerList };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const onMarkerClick = useCallback(
    (id: string | null, marker?: google.maps.marker.AdvancedMarkerElement) => {
      setSelectedId(id);

      if (marker) setSelectedMarker(marker);

      if (id !== selectedId) setInfoWindowShown(true);
      else setInfoWindowShown((isShown) => !isShown);
    },
    [selectedId]
  );

  const handleInfoWindowCloseClick = useCallback(() => {
    setInfoWindowShown(false);
  }, []);

  return (
    <div>
      <Map
        style={{ width: "auto", height: "536px" }}
        defaultCenter={{ lat: markerCenter[0], lng: markerCenter[1] }}
        defaultZoom={15}
        gestureHandling={"greedy"}
        disableDefaultUI
        mapId={window.__env__.GOOGLE_MAPS_ID}
      >
        {markerList.map((data, idx) => {
          const latitude = getLatitude(data);
          const longitude = getLongitude(data);

          if (!latitude || !longitude) return null;

          return (
            <React.Fragment key={`marker-${latitude}-${longitude}-${idx}`}>
              <GGMarker
                key={`${idx}`}
                position={{ lat: latitude, lng: longitude }}
                onMarkerClick={(
                  marker: google.maps.marker.AdvancedMarkerElement
                ) => onMarkerClick(`${idx}`, marker)}
                collisionBehavior={
                  CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY
                }
              />
            </React.Fragment>
          );
        })}

        {infoWindowShown && selectedMarker && selectedId && (
          <InfoWindow
            anchor={selectedMarker}
            pixelOffset={[0, -2]}
            onCloseClick={handleInfoWindowCloseClick}
          >
            <div className="grid grid-cols-2">
              {Object.entries(markerList[Number(selectedId)]).map(
                ([key, val]) => {
                  return (
                    <React.Fragment key={`${key}-key`}>
                      <span key={`${key}-key`} className="font-bold">
                        {key}
                      </span>

                      <span key={`${key}-val`} className="">
                        {`${val}`}
                      </span>
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </InfoWindow>
        )}
      </Map>
    </div>
  );
};
