import clsx from "clsx";
import { useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useTranslation } from "react-i18next";

const baseUrl = window.__env__.BASE_CKAN_URL;
const logo =
  "https://upload.wikimedia.org/wikipedia/commons/6/64/Pattaya_seal.png";

export const Navbar = () => {
  const { t } = useTranslation();
  const items = [
    { label: t("navbar.home"), url: `${baseUrl}` },
    { label: t("navbar.dataset"), url: `${baseUrl}/dataset` },
    { label: t("navbar.group"), url: `${baseUrl}/group` },
    { label: t("navbar.organization"), url: `${baseUrl}/organization` },
    { label: t("navbar.about"), url: `${baseUrl}/about` },
    { label: t("navbar.data_request"), url: `${baseUrl}/data_request/new` },
    { label: t("navbar.announcement"), url: `${baseUrl}/announcement` },
  ];

  const popupRef = useRef<HTMLDivElement>(null);
  useClickOutside(popupRef, () => setOpen(false));

  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white flex items-center" style={{ height: "80px" }}>
      <div
        className={clsx(
          "flex items-center justify-between m-auto px-4",
          "w-full ckan-md:w-[750px] ckan-lg:w-[970px] ckan-xl:w-[1180px]"
        )}
      >
        <a href={baseUrl}>
          <img src={logo} alt="logo" width={48} className="ml-[18px]" />
        </a>

        <div className="h-[40px]">
          <div className="hidden ckan-md:flex items-center justify-center h-full">
            {items.map((item, idx) => (
              <a
                key={`${idx}-${item.label}`}
                className="flex items-center hover:text-[var(--sky-primary-hover)] px-1.5 font-sukhumvit font-semibold text-[0.7rem]"
                href={item.url}
              >
                {item.label}
              </a>
            ))}
          </div>

          <div className="flex ckan-md:hidden items-center h-full relative">
            <button
              className="bg-[var(--sky-primary)] py-1 px-2 rounded-md"
              onClick={() => setOpen(!open)}
            >
              <GiHamburgerMenu className="text-white" />
            </button>

            {open && (
              <div
                ref={popupRef}
                className="absolute top-full right-0 min-w-[10rem] bg-white shadow-lg border mt-1 rounded-lg py-2"
              >
                {items.map((item) => (
                  <a
                    key={item.label}
                    className="flex items-center gap-[6px] px-[10px] cursor-pointer hover:text-[var(--sky-primary-hover)] font-semibold font-sukhumvit text-[0.7rem]"
                    href={item.url}
                  >
                    <p>{item.label}</p>
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
