import { ChangeEvent, FC } from "react";
import { VisualizationField } from "../type";
import { DragButton } from "./DragButton";
import { Divider } from "../../Divider";
import { useTranslation } from "react-i18next";

type SidebarProps = {
  search: string;
  setSearch: (search: string) => void;
  allFields: VisualizationField[];
  searchedFields: VisualizationField[];
  setSearchedFields: (fields: VisualizationField[]) => void;
};

export const Sidebar: FC<SidebarProps> = ({
  search,
  setSearch,
  allFields,
  searchedFields,
  setSearchedFields,
}) => {
  const { t } = useTranslation();
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearch(value);

    // NOTE: Reset the fields if the search input is empty
    if (value === "") return setSearchedFields(allFields);

    // NOTE: Filter the fields based on the search input
    const filteredFields = allFields.filter((field) =>
      field.key.toLowerCase().includes(value.toLowerCase())
    );

    // NOTE: Update the searched fields
    setSearchedFields(filteredFields);
  };

  return (
    <div className="p-3 border-2 border-zinc-300 rounded-lg">
      <div className="flex flex-col items-start gap-3">
        <div className="w-full">
          <h1>{t("visualization_page.attributes")}</h1>

          <Divider className="my-[0]" />
        </div>

        <input
          type="text"
          placeholder={t("visualization_page.search")}
          className="border border-zinc-300 rounded-md p-2 w-full outline-none"
          value={search}
          onChange={handleSearch}
        />

        <div className="flex flex-col gap-2 items-start w-full">
          {searchedFields.map(({ key, type }) => (
            <DragButton key={key} dragKey={key} type={type} />
          ))}
        </div>
      </div>
    </div>
  );
};
