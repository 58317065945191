import { FC, useState } from "react";
import { ColumnChart } from "../../charts/ColumnChart";
import { BarChart } from "../../charts/BarChart";
import { LineChart } from "../../charts/LineChart";
import { AreaChart } from "../../charts/AreaChart";
import { PieChart } from "../../charts/PieChart";
import clsx from "clsx";
import { useChartDataSource } from "../hooks/useChartDataSource";
import { VisualizationChartType, VisualizationField } from "../type";
import { ChartCentreProps } from "../../charts/type";
import { useTranslation } from "react-i18next";

type ChartProps = {
  dataSource: any[];
  xAxisOptions: VisualizationField[];
  yAxisOptions: VisualizationField[];
  title: string;
};

export const Chart: FC<ChartProps> = ({
  dataSource,
  xAxisOptions,
  yAxisOptions,
  title,
}) => {
  const { t } = useTranslation();
  const chartTypeButtons = [
    { label: t("visualization_page.column"), value: "column" },
    { label: t("visualization_page.bar"), value: "bar" },
    { label: t("visualization_page.line"), value: "line" },
    { label: t("visualization_page.area"), value: "area" },
    { label: t("visualization_page.pie"), value: "pie" },
  ] as { label: string; value: VisualizationChartType }[];
  const [chartType, setChartType] = useState<VisualizationChartType>("column");

  const { categories, series } = useChartDataSource({
    chartType,
    dataSource,
    xAxisOptions,
    yAxisOptions,
  });

  const ChartComponent = {
    column: ColumnChart,
    bar: BarChart,
    line: LineChart,
    area: AreaChart,
    pie: PieChart,
  }[chartType];

  return (
    <div className="">
      <div className="flex gap-2 items-center justify-end">
        {chartTypeButtons.map((button) => (
          <button
            key={button.value}
            className={clsx(
              "px-2 rounded-md",
              chartType === button.value ? "bg-green-400" : "bg-zinc-300"
            )}
            onClick={() => setChartType(button.value)}
          >
            {button.label}
          </button>
        ))}
      </div>

      <ChartComponent
        categories={categories}
        series={series as ChartCentreProps["series"]}
        title={title}
      />
    </div>
  );
};
