import { FC, useEffect, useState } from "react";

import { DndContext } from "../dnd/DndContext";
import { Sidebar } from "./components/Sidebar";
import { InputAxis } from "./components/InputAxis";
import { Chart } from "./components/Chart";
import { VisualizationField, VisualizationValueType } from "./type";
import { useDragEnd } from "./hooks/useDragEnd";
import { Divider } from "../Divider";
import { DataDicTable } from "./components/DataDicTable";
import { useTranslation } from "react-i18next";

type TabGraphProps = {
  dataSource: any[];
  dataDict: any[];
  resourceName: string;
};

type VisualizationFields = VisualizationField[];

export const TabGraph: FC<TabGraphProps> = ({
  dataSource,
  dataDict,
  resourceName,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [tempHideChart, setTempHideChart] = useState(false);
  const [allFields, setAllFields] = useState<VisualizationFields>([]);
  const [searchedFields, setSearchedFields] = useState<VisualizationFields>([]);
  const [xAxis, setXAxis] = useState<VisualizationFields>([]);
  const [yAxis, setYAxis] = useState<VisualizationFields>([]);

  const handleOrientationChange = () => {
    setTempHideChart(true);

    setTimeout(() => {
      setTempHideChart(false);
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    const fields = Object.entries(dataSource?.[0] || []).map(
      ([key, value], idx) => {
        let type = typeof value;

        if (!!Number(`${value}`.replaceAll(",", ""))) type = "number";

        return {
          idx,
          key,
          type: type as VisualizationValueType,
          aggregateType: "count",
        } as VisualizationField;
      }
    );

    setSearchedFields(fields);
    setAllFields(fields);
  }, [dataSource]);

  const { handleDragEnd, handleResetAxis } = useDragEnd({
    search,
    allFields,
    setAllFields,
    searchedFields,
    setSearchedFields,
    xAxis,
    setXAxis,
    yAxis,
    setYAxis,
  });

  const handleChangeAggregate = (
    axisKey: string,
    fieldKey: string,
    aggregateType: string
  ) => {
    const axis = axisKey === "x-axis" ? xAxis : yAxis;
    const setAxis = axisKey === "x-axis" ? setXAxis : setYAxis;

    const updatedAxis = axis.map((field) =>
      field.key === fieldKey ? { ...field, aggregateType } : field
    ) as VisualizationFields;

    setAxis(updatedAxis);
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className="flex flex-col ckan-lg:flex-row gap-4">
        <div className="w-full ckan-lg:w-[30%]">
          <Sidebar
            search={search}
            setSearch={setSearch}
            allFields={allFields}
            searchedFields={searchedFields}
            setSearchedFields={setSearchedFields}
          />
        </div>

        <div className="w-full ckan-lg:w-[70%] flex flex-col gap-4 md:p-4">
          <InputAxis
            label={t("visualization_page.x_axis")}
            axisKey="x-axis"
            axisFields={xAxis}
            onResetAxis={handleResetAxis}
          />
          <InputAxis
            label={t("visualization_page.y_axis")}
            axisKey="y-axis"
            axisFields={yAxis}
            onResetAxis={handleResetAxis}
            hasSelectAggregate
            onChangeAggregate={handleChangeAggregate}
          />

          {!tempHideChart && (
            <Chart
              dataSource={dataSource}
              xAxisOptions={xAxis}
              yAxisOptions={yAxis}
              title={resourceName}
            />
          )}

          <Divider />

          <div className="collapse collapse-arrow bg-base-200">
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              {t("visualization_page.data_dictionary")}
            </div>
            <div className="collapse-content overflow-auto">
              <DataDicTable dataDict={dataDict} />
            </div>
          </div>
        </div>
      </div>
    </DndContext>
  );
};
