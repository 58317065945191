import clsx from "clsx";
import { ToggleFontSize } from "./ToggleFontSize";
import { ToggleLang } from "./ToggleLang";
import { Divider } from "../Divider";
import React from "react";
import { useTranslation } from "react-i18next";

const baseUrl = window.__env__.BASE_CKAN_URL;

export const Header = () => {
  const { t } = useTranslation();
  const items = [
    { label: t("header.login"), url: `${baseUrl}/user/login` },
    { label: t("header.register"), url: `${baseUrl}/user/register` },
  ];

  return (
    <div className="bg-[var(--sky-primary)]" style={{ height: "40px" }}>
      <div
        className={clsx(
          "flex items-center justify-between m-auto px-4",
          "ckan-md:w-[750px] ckan-lg:w-[970px] ckan-xl:w-[1180px] "
        )}
      >
        <div className="h-full flex items-center">
          <ToggleFontSize />

          <ToggleLang />
        </div>

        <div className="h-[40px] flex">
          {items.map((item, idx) => (
            <React.Fragment key={`${idx}-${item.label}`}>
              <Divider vertical />

              <a
                key={`${idx}-${item.label}`}
                className="flex items-center text-white hover:underline hover:bg-[var(--sky-primary-hover)] md:px-2 text-nowrap"
                href={item.url}
              >
                {item.label}
              </a>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
